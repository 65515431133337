import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import newsStyles from "../styles/pages/news.module.css"
import { FaChevronCircleRight } from 'react-icons/fa';

import adFoundation from "../images/news/article-1/foundation.png"
// Add more images by just copy pasting one of the lines above and just changing the imgVariable and the imgPath:
// import imgVariable from "imgPath"
// Also don't forget to add your images to images/news/article-X (replace the X with the no. of the article) folder

const NewsPage = () => {
    return (
      <Layout location="news">
        <SEO title="News" />
        <div className={ newsStyles.withPosts }>
          <div className={ newsStyles.container }>
            <h1 className={ newsStyles.title }>News</h1>
            <div className={ newsStyles.content }>
              <h4>1 Post</h4>
              {/* Copy-paste the section below everytime you add a new article */}
              {/* <div className={ newsStyles.card }>
                <div className={ newsStyles.imgWrapper }>
                  <img src={ imgVariable } alt=""/>
                </div>
                <div className={ newsStyles.cardText }>
                  <h3>INSERT TITLE HERE</h3>
                  <h5>INSERT POST DATE HERE</h5>
                  <p>INSERT A PARAGRAPH HERE</p>
                  <p>INSERT NEXT PARAGRAPH OR DELETE</p> 
                  <Link to="/news/article-X/"><span>Continue Reading</span> <FaChevronCircleRight /></Link>
                </div>
              </div> */}
              <div className={ newsStyles.card }>
                <div className={ newsStyles.imgWrapper }>
                  <img src={adFoundation} alt=""/>
                </div>
                <div className={ newsStyles.cardText }>
                  <h3>Ad Foundation turns over comm plan to NCCA</h3>
                  <h5>November 26, 2020</h5>
                  <p>Advertising Foundation of the Philippines, the social development institution of the Philippine marketing communications industry, completed and delivered the...</p>
                  <Link to="/news/article-1/"><span>Continue Reading</span> <FaChevronCircleRight /></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );

    // For when there are no articles, replace the code above with this (uncommented, of course):
    // return (
    //   <Layout location="news">
    //     <SEO title="News" />
    //     <div className={ newsStyles.noPosts }>
    //       <div className={ newsStyles.container }>
    //         <h1 className={ newsStyles.title }>News</h1>
    //         <div className={ newsStyles.content }>
    //           <div className={ newsStyles.card }>
    //             <h5>There are no new posts.</h5>
    //             <p>Check back again later for updates on any events we're holding.</p>
    //             <button onClick={() => {
    //                 window.history.back()
    //             }}>Go Back</button>  
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </Layout>
    // );
  
}

export default NewsPage